import { Formik } from 'formik';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import {object,string,date} from 'yup';
import { Col, Container, Form, Image, Row } from 'react-bootstrap';
import { ConnectedFocusError } from 'focus-formik-error';
import CustomBtn from '../components/CustomBtn';
import {
  user_form,
  sexOpt,
  idOpt,
  educationOpt,
  levelOpt,
  occuptionOpt,
  typeOpt,
  agroOpt,
  selMaybeOpt,
  selOpt,
  fullHustleOpt,
} from '../constants/AppData';
import {keys} from 'lodash';
import { bindActionCreators } from 'redux';
import { saveUserToFirestore, uploadFiles } from '../actions';
import { useHistory } from 'react-router';
import { GREEN, LIGHTBLUE } from '../constants/Colors';
import SubNav from '../components/SubNav';
import profile from '../assets/profile.png';

function EditProfile({ user, saveUserToFirestore, uploadFiles }) {

  const history = useHistory();
  const location = new URL(window.location.href);
  const [uploading, setUploading] = useState(false);
  const [imageUris, setImgUri] = useState();
  const [selectedFile, setSelFile] = useState();
  const from = `${location.searchParams.get('from')}`.replace(/[%2F]+/g, '/');
  const funCallback = (post) => {
    console.log('data funCallback from ', from);
    setUploading(false);

    history.replace(from, { post });
  };
  const handleUriChange = (output, selectedFile) => {
    setImgUri(output);
    setSelFile(selectedFile);
  };
  const onFileChange = (output, handleUriChange) => {
    console.log('File  selected ', output);

    try {
      // Update the state

      const reader = new FileReader();
      reader.onloadend = function (e) {
        if (e.target.error != null) {
          console.log('File  could not be read.');
          return;
        } else {
          handleUriChange(e.target.result, output);
          console.log('File len ' + e.target.result);
        }
      };
      reader.readAsDataURL(output);
    } catch (err) {}
  };
  const progStep = (prog) => {
    console.log(prog);
  };
  const loopFileCallack = (post) => {
    saveUserToFirestore(post[0], funCallback);
  };
  const submitToFb = (currentUser) => {
    setUploading(true);
    const user = { ...currentUser, isComplete: true };
    if (imageUris) {
      uploadFiles(
        { photoUrl: selectedFile },
        user,
        progStep,
        loopFileCallack,
        true
      );
    } else {
      saveUserToFirestore(user, funCallback);
    }
  };
  return (
    <Container>
      <SubNav />
      <br />
      <Formik
        initialValues={user}
        validationSchema={object({
          username: string().required(' name required'),
          id_num: string().required(' id required'),
          phone: string().required(' phone number required'),
          address: string().required(' address required'),
          challenges: string().required(' info required'),
          support: string().required(' info required'),

          email: string()
            .email('Enter valid email')
            .required('email required'),

          sex: string().oneOf(sexOpt.slice(1), 'select valid option'),
          id_type: string().oneOf(idOpt.slice(1), 'select valid option'),
          education: string().oneOf(
            educationOpt.slice(1),
            'select valid option'
          ),
          agro_training: string().oneOf(
            selOpt.slice(1),
            'select valid option'
          ),
          belonging: string().oneOf(selOpt.slice(1), 'select valid option'),
          land_access: string().oneOf(
            selMaybeOpt.slice(1),
            'select valid option'
          ),
          long_term_income: string().oneOf(
            selMaybeOpt.slice(1),
            'select valid option'
          ),
          farming_activities: string().oneOf(
            selMaybeOpt.slice(1),
            'select valid option'
          ),
          occupation: string().oneOf(
            occuptionOpt.slice(1),
            'select valid option'
          ),
          qualifications: string().oneOf(
            levelOpt.slice(1),
            'select valid option'
          ),
          agro_interest: string().oneOf(
            agroOpt.slice(1),
            'select valid option'
          ),
          agro_type: string().oneOf(
            typeOpt.slice(1),
            'select valid option'
          ),
          fulltime_parttime: string().oneOf(
            fullHustleOpt.slice(1),
            'select valid option'
          ),
          land_type: string().oneOf(
            [...selOpt.slice(1), 'Renting'],
            'select valid option'
          ),

          dob: date().required('date of birth required'),
        })}
        onSubmit={submitToFb}
        initialErrors={(errs) => console.log('errs ', errs)}
      >
        {(formik) => (
          <Form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
            }}
          >
            <ConnectedFocusError />
            <Form.Group as={Row} controlId="formBasicFile">
              <Col>
                <Image
                  src={
                    imageUris
                      ? imageUris
                      : user.photoUrl
                      ? user.photoUrl
                      : profile
                  }
                  alt={''}
                  fluid
                  style={{
                    borderRadius: '30px 30px 30px 30px',
                    height: 180,
                    width: 171,
                  }}
                />
              </Col>
              <Col>
                <Form.Control
                  type="file"
                  className="position-relative"
                  required={false}
                  name="file"
                  accept="image/*"
                  onChange={(event) => {
                    onFileChange(
                      event.target.files[0],

                      handleUriChange
                    );
                  }}
                  isInvalid={!!formik.errors.file}
                  feedback={formik.errors.file}
                  id="validationFormik107"
                  feedbackTooltip
                />
              </Col>
            </Form.Group>
            <br />
            {keys(user_form).map((title) => (
              <div key={title}>
                <br />

                <h3 style={{ color: GREEN }}>{title.replace(/[_]+/g, ' ')}</h3>
                {user_form[title].map((entry) => (
                  <Form.Group
                    as={Row}
                    key={entry.name}
                    controlId={`formBasic_${entry.name}`}
                  >
                    <Col sm="5" md="6">
                      <Form.Label>{entry.title}</Form.Label>
                    </Col>
                    <Col>
                      {entry.type === 'select' ? (
                        <Form.Control
                          as="select"
                          name={entry.name}
                          size="sm"
                          value={formik.values[entry.name]}
                          onChange={(event) => {
                            formik.handleChange(event);
                          }}
                          isValid={
                            formik.touched[`${entry.name}`] &&
                            !formik.errors[`${entry.name}`]
                          }
                          isInvalid={
                            formik.touched[`${entry.name}`] &&
                            !!formik.errors[`${entry.name}`]
                          }
                        >
                          {entry.payload &&
                            entry.payload.map((opt, idx) => (
                              <option key={idx} value={opt}>
                                {opt}
                              </option>
                            ))}
                        </Form.Control>
                      ) : (
                        <>
                          {entry.type === 'text' ||
                          entry.type === 'email' ||
                          entry.type === 'date' ? (
                            <Form.Control
                              type={entry.type}
                              name={entry.name}
                              size="sm"
                              placeholder={entry.hint}
                              {...formik.getFieldProps(entry.name)}
                              isValid={
                                formik.touched[`${entry.name}`] &&
                                !formik.errors[`${entry.name}`]
                              }
                              isInvalid={
                                formik.touched[`${entry.name}`] &&
                                !!formik.errors[`${entry.name}`]
                              }
                            />
                          ) : (
                            <Form.Control
                              type="text"
                              name={entry.name}
                              as="textarea"
                              rows={10}
                              placeholder={entry.hint}
                              {...formik.getFieldProps(entry.name)}
                              isValid={
                                formik.touched[`${entry.name}`] &&
                                !formik.errors[`${entry.name}`]
                              }
                              isInvalid={
                                formik.touched[`${entry.name}`] &&
                                !!formik.errors[`${entry.name}`]
                              }
                            />
                          )}
                        </>
                      )}

                      <Form.Control.Feedback type="invalid">
                        {formik.errors[`${entry.name}`]}
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                ))}
              </div>
            ))}

            <div
              style={{
                display: 'flex',
                margin: '17px 0px',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <CustomBtn
                label="Save"
                type="submit"
                uploading={uploading}
                disabled={uploading}
                color1={!uploading ? GREEN : '#c84b1565'}
                color2={!uploading ? LIGHTBLUE : '#c84b1565'}
                margin="0px"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
}
const mapStateToProps = ({ yng_datastore }) => {
  return {
    user: yng_datastore.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveUserToFirestore,
      uploadFiles,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
