import firebaseApp from '../config/fbConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
const functions = getFunctions(firebaseApp);
export const addAdmin = (data, addAdinCallback) => async () => {
  console.log('emailed addAdmin ', data.email);

  return httpsCallable(
    functions,
    'addAdmin'
  )(data).then((response) => {
    addAdinCallback(response);
  });
};
export const verifyTxn = (data, verifyCallback) => async () => {
  console.log('emailed verifyAcc ');

  return httpsCallable(
    functions,
    'addVerification'
  )(data).then((response) => {
    verifyCallback(response);
  });
};
