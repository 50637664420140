import { allCountries } from "../constants/Countries";
import {map,find} from "lodash";

export default function useRegions(origin) {
  let regions = [];
  try {
    regions = map(
      find(allCountries, { countryName: origin })["regions"],
      "name"
    );
    console.log("regions ", regions);
  } catch (err) {}
  return regions;
}
