//import firebase object from firbase.js
import useUUUID from '../hooks/useUUUID';
import { QUERRY_FIRESTORE_SUCCESS, UPDATE, STAT_PATH, SEL } from '../constants';
import { omit } from 'lodash';
import firebaseApp from '../config/fbConfig';

import {
  getFirestore,
  collection,
  doc,
  setDoc,
  writeBatch,
  query,
  where,
  limit,
  getDocs,
  Timestamp,
  onSnapshot,
  getDoc,
} from 'firebase/firestore';

import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';

const db = getFirestore(firebaseApp);
export const selStatAction = (payload) => ({ type: STAT_PATH, payload });
export const selLocAction = (payload) => ({ type: SEL, payload });

export const updateFireAction = (payload) => ({ type: UPDATE, payload });

export const getUserData = (col, type, callback) => async () => {
  const docs = [];
  let q;
  if (type === 'all') {
    q = query(collection(db, col));
  } else {
    let dt = new Date();
    dt.setDate(dt.getDate() - 1);
    const base = new Timestamp(
      Math.round(new Date(dt).getTime() / 1000),
      83000000
    );
    q = query(collection(db, col), where('sub', '>=', base));
  }
  return onSnapshot(q, function (querySnapshot) {
    querySnapshot.docChanges().forEach(function (change) {
      if (change.type === 'added') {
        // doc.data() is never undefined for query doc snapshots
        console.log('query size ', ' = ', querySnapshot.size);
        docs.push({
          ...change.doc.data(),
        });
      }
      if (change.type === 'modified') {
        const position = docs.findIndex(
          (item) => item.post_id === change.doc.data().post_id
        );

        docs.splice(position, 1);
        docs.push({
          ...change.doc.data(),
        });
      }
      if (change.type === 'removed') {
        const position = docs.findIndex(
          (item) => item.post_id === change.doc.data().post_id
        );

        docs.splice(position, 1);
      }
    });
    callback(docs);
  });
};
export const saveUserToFirestore =
  (user, callback, changeData) => async (dispatch) => {
    console.log('saving to fb why ', user, 'path ');

    try {
      console.log('saving get data id ', user, 'get data path ', user.user_id);
      const uid = user.user_id;

      const q = query(
        collection(db, 'users'),
        where('username', '==', user.username),
        limit(1)
      );

      const querySnapshot = await getDocs(q);
      console.log('get payload ', querySnapshot.empty);

      const date_created = new Timestamp(
        Math.round(new Date(user.date_created).getTime() / 1000),
        83000000
      );

      const sub = new Timestamp(
        Math.round(new Date(user.sub).getTime() / 1000),
        83000000
      );

      const username =
        !querySnapshot.empty && uid !== querySnapshot?.docs[0].id
          ? user.username + useUUUID().substr(0, 4)
          : user.username;
      dispatch({
        type: QUERRY_FIRESTORE_SUCCESS,
        payload: user,
      });
      await setDoc(doc(db, 'users', uid), {
        ...user,
        username,

        date_created,

        sub,
      });
      console.log('success adding document: ', user);
      if (callback !== null) callback(changeData || user);

      /* const isValid=path==='lender'?isNotValidUserLender(user):isNotValidUser(user)
    if(!isValid){
      db
      .collection(path)
      .doc(uid)
      .set({...user,username})
      .then(()=> {
        console.log("success adding document: ", user);
        callback({...user,username},isSignup)
      })
      .catch(error=> {
        console.log("Error adding document: ", error);
        dispatch({
          type: SIGNUP_FIRESTORE_ERROR,
          payload: MSG_SIGNUP_FIRESTORE_ERROR,
        });
      });
    }else{
      callback(null,null,null,null,isValid)

    } */
    } catch (err) {
      console.log('Error adding document outer: ', err);

      callback(changeData, err.message);
    }
  };
export const savePostToFirestore = (forms, callback) => async () => {
  console.log(
    'saving to fb savePostToFirestore ',
    forms,
    'post_id ',
    forms.post_id
  );

  try {
    const batch = writeBatch(db);
    forms.forEach((form) => {
      console.log('saving to fb forEach ', form, 'post_id ', form.post_id);
      const newDate = form.post_id ? new Date(form.date) : new Date();
      const post_id = form.post_id || useUUUID();
      const date = new Timestamp(
        Math.round(new Date(newDate).getTime() / 1000),
        83000000
      );
      batch.set(
        doc(db, forms[0].collection, post_id),
        omit({ ...form, date, post_id }, ['collection'])
      );
    });
    await batch.commit();
    if (callback) callback(forms[0].collection);
  } catch (err) {
    console.log('saving to fb why null ', err);
  }
};
const saveToMail = (to, name, data) => async () => {
  const post_id = useUUUID();
  await setDoc(doc(db, 'mail', post_id), {
    to,
    template: {
      name,
      data,
    },
  });
};
export const saveContactForm = (to, name, data, callback) => async () => {
  saveToMail(to, name, data);
  callback();
};
export const getDocFirestore =
  (col, uid, callback, store, user_data) => async (dispatch) => {
    console.log('get data id isCompelete outer');

    try {
      return onSnapshot(doc(db, col, uid), (doc) => {
        if (doc.exists) {
          let data = doc.data();
          console.log('get data isCompelete ', data);
          if (col === 'users') {
            try {
              if (data.date_created) {
                data = {
                  ...data,
                  date_created: data.date_created.toDate(),
                  dob: data.dob.toDate(),
                  live: new Date(data.sub).getTime() >= new Date().getTime(),
                  sub: data.sub.toDate(),
                };
              }
            } catch (err) {}
          } else if (uid !== 'total_users') {
            data = {
              ...data,
              date: data.date.toDate(),
            };
          }
          if (store) {
            dispatch({
              type: UPDATE,
              payload: data,
            });
          }
          if (callback !== null) callback(data, user_data);
        } else {
          if (callback !== null) callback(null, user_data);
        }
      });
    } catch (err) {
      callback(null, user_data, err.message);
    }
  };
export const getCount = (col, id, callback, stat) => async () => {
  console.log('col ', col, 'doc ', id);

  try {
    const docRef = doc(db, col, id);
    const item = await getDoc(docRef);
    console.log('counter ', item.data(), 'col ', col, 'doc ', id);

    callback(item.data().total, stat);
  } catch (error) {
    console.log('col error', error);
  }
};
export const uploadFiles =
  (files, poster, setProgCallback, loopFileCallack, useUser, changeData, opt) =>
  async (dispatch) => {
    let i = 0;
    let found = false;
    const promises = [];
    const posts = [];
    let post = poster;

    if (opt === 1) {
      post = { ...post, url: [] };
    }
    console.log('saving to fb uploadFiles ', opt, 'opt ', opt);

    console.log('saving to fb key data ', files, 'val ', poster);
    const storage = getStorage(firebaseApp);
    Object.entries(files).forEach((entry) => {
      const [key, value] = entry;
      console.log('saving to fb key ', key, 'val ', value);

      let uri = value;

      if (uri !== null) {
        console.log('saving to fb found key ', key, 'val ', value);
        try {
          const storageRef = ref(
            storage,
            `${poster.user_id}/media/${useUUUID()}${uri.name}`
          );
          const task = uploadBytesResumable(storageRef, uri);

          found = true;

          promises.push(task);

          task.on(
            'state_changed',
            (taskSnapshot) => {
              console.log(
                `${taskSnapshot.bytesTransferred} transferred out of ${taskSnapshot.totalBytes}`
              );
              const prog =
                (taskSnapshot.bytesTransferred / taskSnapshot.totalBytes) * 100;
              setProgCallback(`Uploading...${i}/(${parseInt(prog)} %)`);
            },
            function (error) {
              // A full list of error codes is available at
              // https://firebase.google.com/docs/storage/web/handle-errors
              switch (error.code) {
                case 'storage/unauthorized':
                  // User doesn't have permission to access the object
                  break;

                case 'storage/canceled':
                  // User canceled the upload
                  break;

                case 'storage/unknown':
                  // Unknown error occurred, inspect error.serverResponse
                  break;
                default:
              }
            },
            async () => {
              const downloadURL = await getDownloadURL(task.snapshot.ref);
              // do something with the url
              if (opt === 0) {
                post = {
                  ...post,
                  url: downloadURL,
                };
                posts.push(post);
              } else if (opt === 1) {
                post = {
                  ...post,
                  url: [...post.url, downloadURL],
                };
              } else {
                post = {
                  ...post,
                  [key]: downloadURL,
                };
                posts.push(post);
              }

              i++;
              console.log(
                'saving to fb key counter ',
                i,
                'key length ',
                promises.length
              );

              if (i === promises.length) {
                Promise.all(promises)
                  .then(() => {
                    console.log(
                      'saving to fb key download found post inner ',
                      post
                    );
                    if (useUser) {
                      dispatch({
                        type: QUERRY_FIRESTORE_SUCCESS,

                        payload: post,
                      });
                    }
                    loopFileCallack(opt === 1 ? [post] : posts, changeData);
                  })
                  .catch((err) => console.log(err.code));
              }
            }
          );
        } catch (err) {}
      }
    });
    if (!found) {
      console.log('saving to fb key download outer key post ', post);

      loopFileCallack(posts, changeData);
    }
  };
