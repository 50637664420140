import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';

import Wrapper from '../wrapper';
import ImageScroller from '../components/ImageScroller';

import  { team } from '../constants/AppData';
import ngg from '../assets/radio.jpeg';
import ngg1 from '../assets/farm.jpeg';
import ngg2 from '../assets/team.jpeg';

function OurTeam() {

  const homeImg = [ngg, ngg1, ngg2];

  return (
    <Wrapper>
      <ImageScroller bgs={homeImg} objectFit="inherit" />
      <h1
        className="bgg"
        style={{
          width: '100%',
          padding: 10,
          textAlign: 'center',
        }}
      >
        Our Team
      </h1>
      <Row
        xs={1}
        sm={2}
        md={4}
        style={{
          flex: 1,
          justifyContent: 'space-evenly',
        }}
      >
        {team.map((file, idx) => (
          <Col key={idx} className="Icon-links-bg border-o">
            <Image
              src={file.url}
              width={250}
              style={{
                objectFit: 'inherit',
              }}
            />

            <h4>{file.title}</h4>
            <p>{file.position}</p>
          </Col>
        ))}
      </Row>
    </Wrapper>
  );
}

export default OurTeam;
