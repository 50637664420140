import React from 'react'
import { Spinner } from 'react-bootstrap'

function LoadingSpinner() {
    return (
             <div className="App">
            <div
                style={{
                    width: "100%",
                    flex: 1,
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />
                wait.....
              </div>
        </div>
    )
}

export default LoadingSpinner
