import React from 'react';
import styled from 'styled-components';
import { benefits } from '../constants/AppData';
import { GREEN, LIGHTBLUE, ORANGE } from '../constants/Colors';
import {keys,startCase} from 'lodash';
import { FaCheckSquare } from 'react-icons/fa';

const Div = styled.div`
  width: 100%;
  border: rgb(229, 230, 229) 1px solid;
  border-radius: 20px;
  margin-bottom: 20px;

  .top {
    border-radius: 20px 20px 0px 0px;
    padding: 27px;
    background-color: ${GREEN};
  }
  .bottom {
    border-radius: 0px 0px 20px 20px;
    background-color: rgba(251, 251, 251, 0.7);
    padding: 27px;

    ul {
      border-radius: 0px 0px 20px 20px;
      color: ${ORANGE};
      li {
        display: flex;
        flex-direction: row;
        margin: 10px 0px;

        h5 {
          text-align: start;
          margin: 10px;
        }
      }
    }
  }
`;
function ServicesCard() {
  return (
    <Div>
      <div className="top">
        <h3>{'Benefits'}</h3>
      </div>
      <div className="bottom">
        {keys(benefits).map((title) => (
          <div key={title}>
            <br />

            <h5 style={{ color: LIGHTBLUE }}>{startCase(title)}</h5>
            <ul>
              {benefits[title].map((entry) => (
                <li key={entry}>
                  <FaCheckSquare size="2.4em" />
                  <h5>{entry}</h5>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </Div>
  );
}

export default ServicesCard;
