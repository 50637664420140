import {
  SIGNUP_ERROR,
  SIGNOUT_ERROR,
  CURRENT_USER,
  DOMAIN,
} from '../constants';
//import firebase object from firbase.js
import firebaseApp from '../config/fbConfig';

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  updatePassword,
  deleteUser,
  reauthenticateWithCredential,
  updateEmail,
  sendEmailVerification,
  getIdTokenResult,
  onAuthStateChanged,
} from 'firebase/auth';

const auth = getAuth(firebaseApp);

export const currentAction = (payload) => ({ type: CURRENT_USER, payload });

export const resetPassword = (emailAddress, pathName, callback) => async () => {
  const actionCodeSettings = {
    url: DOMAIN + pathName,
  };

  return sendPasswordResetEmail(auth, emailAddress, actionCodeSettings)
    .then(function () {
      // Email sent.
      callback(emailAddress);
    })
    .catch(function (error) {
      // An error happened.
      callback(null, error.message);
    });
};
export const updateUserPassword = (password, callback) => async () => {
  const user = auth.currentUser;

  return updatePassword(user, password)
    .then(function () {
      // Update successful.
      callback();
    })
    .catch(function (error) {
      // An error happened.
      callback(error);
    });
};
export const delUser = () => async () => {
  const user = auth.currentUser;

  return deleteUser(user)
    .then(function () {
      // User deleted.
    })
    .catch(function (error) {
      // An error happened.
    });
};
export const reAuth = (credential, callback) => async () => {
  const user = auth.currentUser;

  return reauthenticateWithCredential(user, credential)
    .then(function () {
      // User re-authenticated.
      callback();
    })
    .catch(function (error) {
      // An error happened.
      callback(error.message);
    });
};
export const resetEmail = (data, callback) => async () => {
  const user = auth.currentUser;

  return updateEmail(user, data.email.val)
    .then(async function () {
      // Update successful.
      try {
        await sendEmailVerification(user);
        // Email sent.
        callback(data);
      } catch (error) {
        // An error happened.
        callback(null, error.message);
      }
    })
    .catch(function (error) {
      // An error happened.
      callback(null, error.message);
    });
};

const sendVerifyEmail = (payload, pathName, callback) => {
  try {
    const actionCodeSettings = {
      url: DOMAIN + pathName,
    };
    const user = auth.currentUser;
    console.log('sign inner auth  successfully ', user.email);

    return sendEmailVerification(user, actionCodeSettings).then(
      () => {
        console.log('sign inner auth  successfully ', user.email);

        callback(payload ? payload : user.email);
      },
      function (err) {
        // An error happened.
        console.log('sign inner auth  err ', err.message);

        callback(null, err.message);
      }
    );
  } catch (err) {
    callback(null, 'Please try again.');
  }
};
export const expVerificationEmail = (pathName, callback) => {
  sendVerifyEmail(null, pathName, callback);
};

export const signup =
  (signUser, password, pathName, callback) => async (dispatch) => {
    console.log('sign inner auth ', signUser, 'password ', password);

    try {
      return createUserWithEmailAndPassword(auth, signUser.email, password)
        .then((userCredential) => {
          const user = userCredential.user;

          sendVerifyEmail(
            { ...signUser, user_id: user.uid },
            pathName,
            callback,
            user
          );
        })
        .catch(function (error) {
          try {
            callback(null, error.message);
          } catch (err) {
            callback(null, 'Please try again.');
          }
          dispatch({
            type: SIGNUP_ERROR,
            payload: 'Something went wrong. Please try again.',
          });
        });
    } catch (err) {
      callback(null, 'Something went wrong. Please try again.');

      dispatch({
        type: SIGNUP_ERROR,
        payload: 'Something went wrong. Please try again.',
      });
    }
  };
export const signin = (email, password, callback) => async (dispatch) => {
  try {
    console.log('sign in');
    return signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        console.log('sign in success');
        onAuthStateChanged(
          auth,
          (user) => {
            console.log('sign inner auth ');
            getIdTokenResult(user).then((idTokenResult) => {
              user.admin =
                idTokenResult.claims.admin !== undefined &&
                idTokenResult.claims.admin !== null
                  ? idTokenResult.claims.admin
                  : false;
              user.super_admin =
                idTokenResult.claims.super_admin !== undefined &&
                idTokenResult.claims.super_admin !== null
                  ? idTokenResult.claims.super_admin
                  : false;

              user.verified =
                idTokenResult.claims.verified !== undefined &&
                idTokenResult.claims.verified !== null
                  ? idTokenResult.claims.verified
                  : false;

              dispatch({
                type: CURRENT_USER,
                payload: user,
              });

              callback(null, !user.admin);
            });
          },
          function (error) {
            console.log(error);
            try {
              callback(null, error.message);
            } catch (err) {
              callback(null, 'Please try again.');
            }
          }
        );
      })
      .catch((err) => {
        console.log('sign in err in ' + err);
        try {
          callback(err.message);
        } catch (err) {
          callback('Please try again.');
        }
      });
  } catch (err) {
    console.log('sign in err inner ' + err);
    try {
      callback(err.message);
    } catch (err) {
      callback('Please try again.');
    }
  }
};

export const signout = (callBack) => async (dispatch) => {
  try {
    return signOut(auth)
      .then(() => {
        callBack();
      })
      .catch(() => {
        dispatch({
          type: SIGNOUT_ERROR,
          payload: 'There was an issue signing out.',
        });
        dispatch({
          type: CURRENT_USER,
          payload: null,
        });
      });
  } catch (err) {
    dispatch({
      type: SIGNOUT_ERROR,
      payload: 'There was an issue signing out.',
    });
  }
};
