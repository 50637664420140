import { public_key } from '../config/flutterConfig';
import useUUUID from './useUUUID';

export default function useFlutterwave(
  file,
  currency,
  country,
  consumer_id,
  email,
  phone_number,
  name,
  description,
  txnCallback
) {
  // eslint-disable-next-line no-undef
  FlutterwaveCheckout({
    public_key,
    tx_ref: useUUUID(),
    amount: file.amount,
    currency,
    country,
    payment_options: 'card, mobilemoneyzambia',

    meta: {
      consumer_id,
      consumer_mac: '92a3-912ba-1192a',
    },
    customer: {
      email,
      phone_number,
      name,
    },
    callback: function (data) {
      console.log('data people', data);
      txnCallback(data, file);
    },
    onclose: function () {
      // close modal
      console.log('data close');
    },
    customizations: {
      title: 'YEFI',
      description,
      logo: 'https://firebasestorage.googleapis.com/v0/b/yefiafrica.appspot.com/o/logo.jpeg?alt=media&token=e2a79c37-e91c-490a-80d7-9463841248fd',
    },
  });
}
