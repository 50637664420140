import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
const PayRoute = ({ location, children, ...rest }) => {
  const auth = useSelector(({ yng_authReducer }) => yng_authReducer.current);
  const isComplete = useSelector(
    ({ yng_datastore }) => yng_datastore.user.isComplete
  );
  const reAuth = location.state?.reAuth;

  const { email, emailVerified, isEmpty } = auth;
  console.log('get auth ', auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isEmpty ? (
          <>
            {!email || emailVerified || reAuth ? (
              <>
                {isComplete ? (
                  children
                ) : (
                  <Redirect
                    to={{
                      pathname: `/edit_profile/return?from=${location.pathname.replace(
                        '/',
                        '%2F'
                      )}`,
                      state: {
                        from: location.pathname,
                        email,
                        type: 'Email Verification',
                      },
                    }}
                  />
                )}
              </>
            ) : (
              <Redirect
                to={{
                  pathname: `/email_verification`,
                  state: {
                    from: location.pathname,
                    email,
                    type: 'Email Verification',
                  },
                }}
              />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: `/signin/return?from=${location.pathname.replace(
                '/',
                '%2F'
              )}`,
              state: {
                from: location.pathname,
                type: 'any',
                num: 1,
                subPath: '',
              },
            }}
          />
        )
      }
    />
  );
};

export default PayRoute;
