import React from 'react';
import { Redirect, Route } from 'react-router';
import { useSelector } from 'react-redux';
function UserAccRoute({ location, children, ...rest }) {
  const auth = useSelector(({ yng_authReducer }) => yng_authReducer.current);
  const reAuth = location.state?.reAuth;

  const { email, emailVerified, isEmpty } = auth;
  console.log('get auth ', auth);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !isEmpty ? (
          <>
            {!email || emailVerified || reAuth ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: `/email_verification`,
                  state: {
                    from: location.pathname,
                    email,
                    type: 'Email Verification',
                  },
                }}
              />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: `/signin/return?from=${location.pathname.replace(
                '/',
                '%2F'
              )}`,
              state: {
                from: location.pathname,
                type: 'any',
                num: 1,
                subPath: '',
              },
            }}
          />
        )
      }
    />
  );
}

export default UserAccRoute;
