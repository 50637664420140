import React, { useState } from 'react';

import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Button, Spinner } from 'react-bootstrap';
import logo from '../assets/logo.jpeg';
import { DOMAIN } from '../constants';
import firebaseApp from '../config/fbConfig';

import {
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
} from 'firebase/auth';
const auth = getAuth(firebaseApp);
function AuthLink({ current }) {
  const [searching, setSearching] = useState(false);
  const history = useHistory();

  const { from, email, type } = history.location.state;
  const resetPassword = () => async () => {
    const actionCodeSettings = {
      url: DOMAIN + from,
    };
    sendPasswordResetEmail(auth, email, actionCodeSettings)
      .then(function () {
        // Email sent.
        setSearching(false);
      })
      .catch(function (error) {
        // An error happened.
        setSearching(false);
      });
  };
  const sendVerifyEmail = () => {
    try {
      const actionCodeSettings = {
        url: 'https://loanpax.com' + from,
      };
      const user = auth.currentUser;

      return sendEmailVerification(user, actionCodeSettings).then(
        () => {
          console.log('sign inner auth  successfully ', user.email);

          setSearching(false);
        },
        function (err) {
          // An error happened.
          console.log('sign inner auth  err ', err.message);

          setSearching(false);
        }
      );
    } catch (err) {
      setSearching(false);
    }
  };

  return (
    <>
      <div className="Container bgg">
        <div className="Login-form">
          <div className="App">
            <img alt="" src={logo} width={140} className="App" />
            <h1>{type}</h1>
            <p>{`Check ${email} for verification link`}</p>
          </div>
          {(!current.isEmpty || type === 'Password Reset') && (
            <Button
              variant="warning"
              type="button"
              size="lg"
              block
              disabled={searching}
              onClick={() => {
                setSearching(true);
                if (type === 'Password Reset') {
                  resetPassword();
                } else {
                  sendVerifyEmail();
                }
              }}
            >
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: searching ? 'flex' : 'none',
                }}
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                wait.....
              </div>
              <div
                style={{
                  width: '100%',
                  flex: 1,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: !searching ? 'flex' : 'none',
                }}
              >
                Send link
              </div>
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    current: state.yng_authReducer.current,
  };
};

export default connect(mapStateToProps)(AuthLink);
