import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Parallax } from 'react-parallax';
import { connect } from 'react-redux';
import ngg from '../assets/africa.jpg';
import StatCard from './StatCard';
import VisibilitySensor from 'react-visibility-sensor';
import {startCase} from 'lodash';
import { GREEN, ORANGE } from '../constants/Colors';
const ParallaxComp = ({ stats }) => {
  return (
    <VisibilitySensor partialVisibility={true}>
      {({ isVisible }) => (
        <div style={{ margin: '5% 0% 0% 0%' }}>
          <Parallax blur={10} strength={300} bgImage={ngg} bgImageAlt={'ngg'}>
            <div>
              <h1 style={{ color: GREEN, textAlign: 'center', marginTop: 30 }}>
                {'Join our growing team of farmers, partners and network'}
              </h1>
              <br />
              <Row xs={1}  style={{ marginBottom: 30 }}>
                {Object.entries(stats).map((item) => (
                  <Col key={item[0]}>
                    <StatCard
                      status={item[1]}
                      title={startCase(item[0])}
                      isVisible={isVisible}
                      iconColor={ORANGE}
                      txtColor={ORANGE}
                    />
                  </Col>
                ))}
              </Row>
            </div>
          </Parallax>
        </div>
      )}
    </VisibilitySensor>
  );
};
const mapStateToProps = ({ yng_datastore }) => {
  return {
    stats: yng_datastore.stats,
  };
};
export default connect(mapStateToProps)(ParallaxComp);
