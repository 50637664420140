import React, { useEffect, useState } from 'react';
import SubNav from '../components/SubNav';
import { getUserData } from '../actions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container } from 'react-bootstrap';
import LoadingSpinner from '../components/LoadingSpinner';
import UsersList from '../components/UsersList';

const Users = ({ getUserData }) => {
  const [faqs, setFaqs] = useState(null);
  const [sel, setSel] = useState(0);
  useEffect(() => {
    const location = new URL(window.location.href);

    const type = location.searchParams.get('type');
    const path = location.searchParams.get('path');
    const sel = location.searchParams.get('sel');
    setSel(sel);

    const subscriber = getUserData(path.replace('%2F', '/'), type, setFaqs);
    return subscriber; // unsubscribe on unmount
  }, []);
  return (
    <Container>
      <SubNav />
      <br />
      {faqs ? <UsersList faqs={faqs} sel={sel} /> : <LoadingSpinner />}
    </Container>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getUserData }, dispatch);
};
export default connect(null, mapDispatchToProps)(Users);
