import React, { useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addAdmin } from '../actions';
import Squared from '../components/Squared';
import {startCase} from 'lodash';
import { actions } from '../constants/AppData';
import { GREEN, LIGHTBLUE } from '../constants/Colors';
import CustomBtn from '../components/CustomBtn';
import InputModal from '../components/InputModal';
import Wrapper from '../wrapper';

const Apanel = ({ isSuper, addAdmin, stats }) => {

  const [modalShow, setModalShow] = useState(false);
  const [payload, setPayload] = useState({
    action: 'Save',
    data: { title: 'Account Admin', body: 'Assign account admin' },
    uploading: false,

    req: ['title'],
  });
  const addAdminCallback = (response) => {
    console.log('emailed response ', response);

    setPayload({
      action: 'Done',
      data: { title: 'Assign Admin', body: response.data.message },
      uploading: false,

      req: ['title'],
    });
    setModalShow(true);
  };
  const handleAction = (opt) => {
    setPayload({
      action: opt.title,
      data: {
        title: opt.title,
        body: opt.action,
        email: '',
        super_admin: false,
        admin: false,
      },
      uploading: false,

      req: ['email'],
    });
    setModalShow(true);
  };

  return (
    <Wrapper>
      <br />
      {stats && (
        <Row>
          {Object.entries(stats).map((item) => (
            <Col key={item[0]}>
              <Squared
                type={item[0] === 'registered_users' ? 'all' : 'sub'}
                status={item[1]}
                title={startCase(item[0])}
              />
            </Col>
          ))}
        </Row>
      )}
      <br />

      {isSuper && (
        <Table>
          <thead>
            <tr>
              <th>Action</th>
              <th>Info</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {actions.map((item) => (
              <tr key={item.title}>
                <td>{item.title}</td>
                <td>{item.info}</td>
                <td>
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <CustomBtn
                      label={item.title}
                      type="button"
                      btnClicked={() => handleAction(item)}
                      color1={GREEN}
                      color2={LIGHTBLUE}
                      margin="3px"
                    />
                  </Col>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <InputModal
        payload={payload}
        show={modalShow}
        onActionFun={(data) => {
          if (payload.action === 'Done') {
            setModalShow(false);
          } else if (payload.action === 'Make Admin') {
            console.log('Make Admin data ', data, 'email ', data.email);

            addAdmin(data, addAdminCallback);
          }
        }}
        setUploading={() => setPayload({ ...payload, uploading: true })}
        onHide={() => setModalShow(false)}
      />
    </Wrapper>
  );
};
function mapStateToProps({ yng_authReducer, yng_datastore }) {
  return {
    isSuper: yng_authReducer?.current?.super_admin,
    stats: yng_datastore.stats,
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      addAdmin,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Apanel);
