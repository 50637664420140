import { createStore, applyMiddleware, compose } from 'redux';
import combineReducers from '../reducers';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import firebaseApp from '../config/fbConfig';

const persistConfig = {
  key: 'root',
  storage: storage,
  whiteList: ['yng_datastore', 'yng_authReducer', 'yng_menuReducer'],
};
const middlewares = [thunk.withExtraArgument(firebaseApp), createLogger()];

const enHanceCreateStore = compose(applyMiddleware(...middlewares))(
  createStore
);
const persistedReducer = persistReducer(persistConfig, combineReducers);

export const store = enHanceCreateStore(persistedReducer);
export const persistedStore = persistStore(store);
