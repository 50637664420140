import React, { useState } from 'react';
import { Col, Form, Row, Toast } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { ConnectedFocusError } from 'focus-formik-error';
import { saveContactForm } from '../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { GREEN, LIGHTBLUE } from '../constants/Colors';
import CustomBtn from '../components/CustomBtn';
import Wrapper from '../wrapper';
function Contactus({ saveContactForm, user }) {
  const [show, setShow] = useState(false);

  const [uploading, setUploading] = useState(false);
  const [msg, setMsg] = useState({
    email_address: user.email,
    body: '',
    date: '',

    name: user.username,
    subject: '',
    number: '',
  });
  const contactCallback = () => {
    setUploading(false);
    setShow(true);
    setMsg({
      email_address: user.email,
      body: '',
      date: '',

      name: user.username,
      subject: '',
      number: '',
    });
  };
  const submitToFb = (data) => {
    saveContactForm(
      'ngoyefi@gmail.com',
      'new_contact',
      {
        body: data.body,
        subject: `Contact form from ${data.name} | ${user.type}`,

        replyTo: `${data.email_address} | ${data.number}`,
        imagePath:
          'https://firebasestorage.googleapis.com/v0/b/yefiafrica.appspot.com/o/logo.jpeg?alt=media&token=e2a79c37-e91c-490a-80d7-9463841248fd',
      },
      contactCallback
    );
  };
  return (
    <Wrapper>
      <br />
      <Row>
        <Toast
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            color: 'black',
          }}
          onClose={() => setShow(false)}
          show={show}
          delay={3000}
          autohide
        >
          <Toast.Header>
            <strong className="mr-auto">Sent Successfully</strong>
          </Toast.Header>
          <Toast.Body>Thanks, your message has been recieved</Toast.Body>
        </Toast>
        <Formik
          enableReinitialize
          initialValues={msg}
          validationSchema={Yup.object({
            email_address: Yup.string()
              .email('Enter valid email')
              .required('email required'),
            name: Yup.string().required('name required'),
            number: Yup.number().required('number required'),
            body: Yup.string().required('message required'),
            date: Yup.string().required('date required'),
          })}
          onSubmit={submitToFb}
        >
          {(formik) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <ConnectedFocusError />
              <Form.Group as={Row} controlId="formBasicName">
                <Col>
                  <Form.Label>Your name</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="email"
                    size="sm"
                    name="name"
                    placeholder="Enter your name"
                    {...formik.getFieldProps('name')}
                    isValid={formik.touched.name && !formik.errors.name}
                    isInvalid={formik.touched.name && !!formik.errors.name}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.name}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formBasicEmail">
                <Col>
                  <Form.Label>Your Email</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="email_address"
                    name="email_address"
                    size="sm"
                    placeholder="Enter email_address"
                    {...formik.getFieldProps('email_address')}
                    isValid={
                      formik.touched.email_address &&
                      !formik.errors.email_address
                    }
                    isInvalid={
                      formik.touched.email_address &&
                      !!formik.errors.email_address
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email_address}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formMaxium">
                <Col>
                  <Form.Label>Phone number</Form.Label>
                </Col>

                <Col>
                  <Form.Control
                    placeholder="Phone number"
                    name="number"
                    {...formik.getFieldProps('number')}
                    isValid={formik.touched.number && !formik.errors.number}
                    isInvalid={formik.touched.number && !!formik.errors.number}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.number}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formBasicDate">
                <Col>
                  <Form.Label>
                    To check you are human, please enter today's date
                  </Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    type="email"
                    name="date"
                    size="sm"
                    placeholder="DD/MM/YYYY"
                    {...formik.getFieldProps('date')}
                    isValid={formik.touched.date && !formik.errors.date}
                    isInvalid={formik.touched.date && !!formik.errors.date}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.date}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Col>
                  <Form.Label>Your Message</Form.Label>
                </Col>
                <Col>
                  <Form.Control
                    name="body"
                    size="sm"
                    type="text"
                    as="textarea"
                    rows={10}
                    placeholder="Enter your message"
                    {...formik.getFieldProps('body')}
                    isValid={formik.touched.body && !formik.errors.body}
                    isInvalid={formik.touched.body && !!formik.errors.body}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.body}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <br />
              <div
                style={{
                  display: 'flex',
                  margin: '17px 0px',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                }}
              >
                <CustomBtn
                  label="Send Message"
                  type="submit"
                  uploading={uploading}
                  disabled={uploading}
                  color1={!uploading ? GREEN : '#c84b1565'}
                  color2={!uploading ? LIGHTBLUE : '#c84b1565'}
                  margin="0px"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Row>
    </Wrapper>
  );
}
const mapStateToProps = ({ yng_datastore }) => {
  return {
    user: yng_datastore.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      saveContactForm,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Contactus);
