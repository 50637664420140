import React from 'react';
import { FaUsers } from 'react-icons/fa';
import CountUp from 'react-countup';
const StatCard = ({ status, title, isVisible, iconColor, txtColor }) => {
  return (
    <>
      <div className="Icon-links">
        <FaUsers size={'3.3rem'} color={iconColor} />

        <h1 style={{ color: txtColor,fontSize:60 }}>
          {isVisible ? <CountUp end={status} duration={3} /> : 0}
        </h1>
        <h3 style={{ color: iconColor }}>{title}</h3>
      </div>
      <hr style={{ color: '#ec962f' }} />
    </>
  );
};

export default StatCard;
