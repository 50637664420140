import React from 'react';
import { Navbar } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import logo from '../assets/logo.jpeg';
import { GREEN } from '../constants/Colors';

function SubNav() {
  const history = useHistory();

  return (
    <>
      <Navbar style={{ borderBottom: `3px solid ${GREEN}` }} variant="light">
        <Navbar.Brand>
          <img
            alt=""
            src={logo}
            width={240}
            style={{ cursor: 'pointer', margin: '5px 17px' }}
            onClick={() => history.push(`/`)}
          />
        </Navbar.Brand>
      </Navbar>
    </>
  );
}

export default SubNav;
