import React from 'react';
import { Table } from 'react-bootstrap';
import UserCells from './UserCells';

const UsersList = ({ faqs }) => {
  return (
    <>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Full name</th>

            <th>Address</th>
            <th>Contact</th>
            <th>Email</th>

            <th>End of membership</th>

            <th>Amount</th>
            <th>More</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((item) => (
            <UserCells key={item.user_id} data={item} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default UsersList;
