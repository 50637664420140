import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import firebaseApp from '../config/fbConfig';

import {
  currentAction,
  getCount,
  getDocFirestore,
  selLocAction,
  selStatAction,
} from '../actions';
import {find,filter} from 'lodash';
import { getAuth, onIdTokenChanged, getIdTokenResult } from 'firebase/auth';
//import { countries } from '../constants/AppData';
import useRegions from '../hooks/useRegions';
import { googleMapsApiKey } from '../config/google_maps';
import SplashComp from '../components/SplashComp';
import { countries } from '../constants/AppData';

const auth = getAuth(firebaseApp);

function AuthIsLoaded({
  children,
  currentAction,
  getDocFirestore,
  selStatAction,
  getCount,
  selLocAction,
}) {
  const [current, setstate] = useState(false);
  console.log('check currentUser state ', current);

  const setSubs = (stat, stat2) => {
    selStatAction({ registered_users: stat2, members: 500000 });
    console.log('stat subscibed_users ', stat);
    setstate(true);
  };
  const setAll = (stat) => {
    //selStatAction({ ...stats, registered_users: stat });
    console.log('stat registered_users ', stat);

    getCount('total_subs', 'sub_users', setSubs, stat);
  };
  const authCallback = () => {
    //getCount('total_users', 'all_users', setAll);
    selStatAction({ members: 500000 });
    //console.log('stat subscibed_users ', stat);
    setstate(true);
  };
  const onAuthStateChanged = (currentUser) => {
    console.log('check currentUser token ', currentUser);

    if (currentUser) {
      getIdTokenResult(currentUser).then((idTokenResult) => {
        currentUser.admin =
          idTokenResult.claims.admin !== undefined &&
          idTokenResult.claims.admin !== null
            ? idTokenResult.claims.admin
            : false;
        currentUser.super_admin =
          idTokenResult.claims.super_admin !== undefined &&
          idTokenResult.claims.super_admin !== null
            ? idTokenResult.claims.super_admin
            : false;

        currentUser.lender =
          idTokenResult.claims.lender !== undefined &&
          idTokenResult.claims.lender !== null
            ? idTokenResult.claims.lender
            : false;
        currentUser.borrower =
          idTokenResult.claims.borrower !== undefined &&
          idTokenResult.claims.borrower !== null
            ? idTokenResult.claims.borrower
            : false;
        currentUser.verified =
          idTokenResult.claims.verified !== undefined &&
          idTokenResult.claims.verified !== null
            ? idTokenResult.claims.verified
            : false;
        currentAction(currentUser);
        console.log(
          'currentUser token ',
          idTokenResult.claims,
          'currentUser token borrower ',
          currentUser.borrower
        );
        getDocFirestore('users', currentUser.uid, null, true);

        authCallback();
      });
    } else {
      currentAction({ isEmpty: true });
      authCallback();
    }
  };
  const UpdateRegions = (origin) => {
    return useRegions(origin);
  };
  function showPosition(position) {
    console.log(
      'Latitude: ',
      position.coords.latitude,
      '<br>Longitude: ',
      position.coords.longitude,
      'key ',
      googleMapsApiKey
    );
    fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&sensor=false&key=${googleMapsApiKey}`
    )
      .then((res) => res.json())
      .then((res) => {
        try {
          console.log('location all res ', res.results);
          const country = find(
            res.results[0].address_components,
            function (o) {
              return o.types.includes('country');
            }
          );
          const region = find(
            res.results[0].address_components,
            function (o) {
              return o.types.includes('administrative_area_level_2');
            }
          )['long_name'];
          const town = find(res.results[0].address_components, function (o) {
            return o.types.includes('locality');
          })['long_name'];
          const regions = filter(
            UpdateRegions(country.long_name),
            function (o) {
              console.log('country splitAtom[0] ', region, 'regions ', o);

              return region.includes(o);
            }
          );
          const currency = find(countries, {
            countryName: country.long_name,
          })['currencyCode'];
          selLocAction({
            currently_in: country.long_name,
            code: country.short_name,
            region: regions.length > 0 ? regions[0] : '',
            town,
            currency: currency !== 'ZMW' ? 'USD' : 'ZMW',
          });
        } catch (err) {
          console.log('currently_in err ', err);
        }
      })
      .catch((err) => alert(err));
  }
  function getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(showPosition);
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }
  useEffect(() => {
    getLocation();
    const subscriber = onIdTokenChanged(auth, onAuthStateChanged);
    return subscriber; // unsubscribe on unmount
  }, []);
  if (!current)
    return <SplashComp/>;
  return children;
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      currentAction,
      getDocFirestore,
      selStatAction,
      getCount,
      selLocAction,
    },
    dispatch
  );
};
export default connect(null, mapDispatchToProps)(AuthIsLoaded);
