export const UPDATE = 'UPDATE';
export const CURRENT_USER = 'CURRENT_USER';
export const DOMAIN = 'https://yefiafrica.com';

export const SIGNUP_ERROR = 'SIGNUP ERROR';
export const SIGNOUT_ERROR = 'SIGNOUT_ERROR';
export const MSG_SIGNIN_ERROR = 'MSG_SIGNIN_ERROR';
export const MSG_SIGNUP_ERROR = 'MSG_SIGNUP_ERROR';
export const STAT_PATH = 'STAT_PATH';
export const SEL = 'SEL';

export const QUERRY_FIRESTORE_SUCCESS = 'QUERRY_FIRESTORE_SUCCESS';
export const QUERRY_FIRESTORE_ERROR = 'QUERRY_FIRESTORE_ERROR';
export const MSG_QUERRY_FIRESTORE_ERROR = 'error adding to firestore.';
