import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useFlutterwave from '../hooks/useFlutterwave';
import { saveUserToFirestore, verifyTxn } from '../actions';
import { useHistory } from 'react-router';
import { Button, Col, Container, Row } from 'react-bootstrap';
import SubNav from '../components/SubNav';
import { packages } from '../constants/AppData';
import { ORANGE } from '../constants/Colors';
import ServicesCard from '../components/ServicesCard';
const Pay = ({ verifyTxn, user, saveUserToFirestore }) => {
  const history = useHistory();
  const currency = 'ZMW';
  const { user_id, email, phone, username, live, amount, sub } = user;
  const funCallback = () => {
    console.log('data funCallback ');
    history.replace('/');
  };
  const txnCallback = (txn) => {
    console.log('data txnCallback started ', txn);
    verifyTxn(
      {
        ...txn,
        user_id,
        type: 'sub',
        days: 365,
        date: new Date().toString().substring(4, 15),
      },
      funCallback
    );
  };
  const updateUser = () => {
    let sub = new Date();

    sub.setDate(sub.getDate() + 365);
    saveUserToFirestore({ ...user, sub, amount: 0 }, funCallback);
  };
  const UpdateFlutterwave = (doc) => {
    useFlutterwave(
      doc,
      currency,
      'ZM',
      user_id,
      email,
      phone,
      username,
      'Subscription Payment',
      txnCallback
    );
  };

  return (
    <Container>
      <SubNav />
      <br />
      <Row>
        <Col>
          {!live || amount === 0 ? (
            <>
              {packages.map((file) => (
                <div key={file.name}>
                  <p>{file.description}</p>
                  <h6
                    style={{
                      color: ORANGE,
                    }}
                  >{`${currency} ${file[currency]}`}</h6>
                  <Button
                    onClick={() => {
                      if (file.ZMW === 0) {
                        updateUser();
                      } else {
                        UpdateFlutterwave(file);
                      }
                    }}
                  >
                    Proceed to payment
                  </Button>
                  <hr />
                </div>
              ))}
            </>
          ) : (
            <p
              style={{ margin: 17 }}
            >{`${username}, your payment is valid until ${new Date(sub)
              .toString()
              .substring(4, 15)}`}</p>
          )}
        </Col>
        <Col>
          <ServicesCard />
        </Col>
      </Row>
    </Container>
  );
};

const mapStateToProps = ({ yng_datastore }) => {
  return {
    user: yng_datastore.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyTxn,
      saveUserToFirestore,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Pay);
