import { combineReducers } from 'redux';
// import keys from './keys_reducers';
import authReducer from './AuthReducer';
import storeReducer from './firestoreReducers';

// export default counter

export default combineReducers({
  yng_datastore: storeReducer,

  yng_authReducer: authReducer,
});
