//import action-types
import { CURRENT_USER } from '../constants';
//initialize state
const INITIAL_STATE = {
  current: { isEmpty: true },
};
//reducer
const authReducer = function (state = INITIAL_STATE, action) {
  //write conditional to handle action-response
  if (action.type === CURRENT_USER) {
    console.log('current authMsg ', action.payload);

    return { ...state, current: action.payload };
  } else {
    return state;
  }
};
export default authReducer;
