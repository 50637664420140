//import action-types
import { SEL, STAT_PATH, UPDATE } from '../constants';
import {user} from '../constants/AppData';
//initialize state
const INITIAL_STATE = {
  user,
  stats: {},
  loc: {
    currently_in: 'Zambia',
    region: '',
    town: '',
    code: 'ZM',
    currency: 'ZMW',
  },
};

//reducer
// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = INITIAL_STATE, action) {
  //write conditional to handle action-response
  if (action.type === UPDATE) {
    console.log('user storeMsg ', action.payload);

    return { ...state, user: action.payload };
  } else if (action.type === STAT_PATH) {
    console.log('user storeMsg ', action.payload);

    return { ...state, stats: action.payload };
  } else if (action.type === SEL) {
    console.log('user storeMsg ', action.payload);

    return {
      ...state,
      loc: action.payload,
    };
  } else {
    return state;
  }
}
