import { useEffect, useState } from 'react';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ParallaxComp from '../components/ParallaxComp';
import { partner1, partner2 } from '../constants/AppData';
import Wrapper from '../wrapper';
import SplashComp from '../components/SplashComp';

const Home = () => {
  const history = useHistory();

  const { username, live, amount, sub } = useSelector(({ yng_datastore:{user} })=>user);
  const width = window.innerWidth;
  const realWidth = width - (width / 100) * 10;

  const [match1, setMatch1] = useState(
    window.matchMedia('(min-width: 875px)').matches
  );
  
  useEffect(() => {
    const handler1 = (e) => setMatch1(e.matches);
    window.matchMedia('(min-width: 875px)').addListener(handler1);
  }, []);
  return (
    <Wrapper>
      <Row className="bgg">
        <Col>
          {!live ? (
            <>
              <h1>Become a member</h1>
              <h6>
                Support us by becoming a paid member and enjoy lots of benefits
              </h6>
            </>
          ) : (
            <>
              {amount === 0 ? (
                <>
                  <p
                    style={{ margin: 17 }}
                  >{`${username}, thanks for your membership. Your free membership is valid until ${new Date(
                    sub
                  )
                    .toString()
                    .substring(
                      4,
                      15
                    )}. Consider upgrading to the preminium membership for more access`}</p>
                </>
              ) : (
                <>
                  <p
                    style={{ margin: 17 }}
                  >{`${username}, thanks for your membership. Your payment is valid until ${new Date(
                    sub
                  )
                    .toString()
                    .substring(
                      4,
                      15
                    )}. Kindly extend an invitation to your friends`}</p>
                </>
              )}
            </>
          )}
        </Col>
        {(!live || amount === 0) && (
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={() => history.push('/subscribe')}
              variant="warning"
            >
              {live && amount === 0 ? 'Upgrade' : 'Join Now'}
            </Button>
          </Col>
        )}
      </Row>
      <ParallaxComp />
      <Row style={{ margin: '10% 0% 0% 0%' }}>
        <Col>
            <SplashComp className='Icon-links' width='23em'/>
        </Col>
        <Col>
          <h1 className="green-header">YEFI HISTORY</h1>
          <p>{`In 2014, Young Emerging Farmers Initiative (YEFI) was founded at the
            University of Zambia by very vibrant and energetic young people. The
            establishment of this revolutionary movement of young people was due to
            the high youth unemployment levels in the country. In 2015, YEFI was
            officially registered as an NGO under the NGO Act of 2009 of the laws of
            Zambia. YEFI has collaborated with different organisations and institutions
            such as AgriProFocus, Agents of Change, Agri-smart, Oxfam Zambia, WWF
            Zambia, MashMap Consultancy, ILO/FAO, Gaia Education, Ministry of Youths
            and Sport, Ministry of Agriculture (NAIS) as well as COMESA since its
            establishment. In 2016 YEFI launched a My Farm My Swag Campaign to reach
            out to young people especially in urban areas. In 2017 with the help of
            MashMap Consultancy and COMESA, YEFI launched the establishment of
            Youth Agriculture Hubs in Zambia, developed and completed the project
            document of the Youth Hub.`}</p>

          
        </Col>
      </Row>
      <Row style={{ background: 'white', margin: '10% 0% 0% 0%' }}>
        <h1>Our Current Partners</h1>
        <Row xs={3} sm={3} md={6} lg={6} xl={6}>
          {partner1.map((file, idx) => (
            <Col
              key={idx}
              style={{ width: match1 ? realWidth / 6 : '4.3rem', marginTop: 7 }}
            >
              <Image src={file} fluid />
            </Col>
          ))}
        </Row>
      </Row>
      <Row style={{ background: 'white', margin: '10% 0% ' }}>
        <h1>Other partners we have worked with</h1>
        <Row xs={3} sm={3} md={6} lg={6} xl={6}>
          {partner2.map((file, idx) => (
            <Col
              key={idx}
              style={{ width: match1 ? realWidth / 4 : '4.3rem', marginTop: 7 }}
            >
              <Image src={file} fluid />
            </Col>
          ))}
        </Row>
      </Row>
    </Wrapper>
  );
};
/* const mapStateToProps = ({ yng_datastore }) => {
  return {
    user: yng_datastore.user,
  };
}; */

export default Home;
