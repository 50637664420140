import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import {user as init_user} from '../../constants/AppData';
import { ORANGE, LIGHTBLUE } from '../../constants/Colors';
import { bindActionCreators } from 'redux';

import InputModal from '../InputModal';
import { signout, updateFireAction } from '../../actions';

const Ul = styled.ul`
  list-style: none;
  display: flex;
  flex-flow: row nowrap;
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
  color: ${LIGHTBLUE};
  justify-content: flex-end;

  li {
    padding: 10px;
    margin-right: 7px;
    align-content: center;
  }
  li#out {
    color: white;
    border: 2px solid black;
    border-radius: 10px 10px 10px 10px;
    background-color: rgb(231, 114, 37);
  }
  li.Active {
    color: #ec962f;
    transition: 0.5s;
  }

  li:hover {
    color: ${ORANGE};
    transition: 0.5s;
  }

  @media (max-width: 1199px) {
    li {
      padding: 7px;
      margin-right: 4px;
      align-content: center;
    }
  }
  @media (max-width: 1007px) {
    flex-flow: column nowrap;
    justify-content: flex-start;

    background-color: ${LIGHTBLUE};
    opacity: 0.9;
    z-index: 22; /* Specify a stack order in case you're using a different order for other elements */
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 300px;
    padding-top: 3.5rem;
    transition: transform 0.3s ease-in-out;

    li {
      color: #fff;
    }
  }
`;

const RightNav = ({ open, current, user, signout, updateFireAction }) => {
  const history = useHistory();

  const location = history.location;
  const path = location.pathname;

  const [modalShow, setModalShow] = useState(false);

  const payload = {
    action: 'Sign Out',
    data: { title: 'Sign Out', body: 'Do you want to Sign out?' },
    uploading: false,

    req: ['title'],
  };
  const signOutCallBack = () => {
    updateFireAction(init_user);
    history.replace('/');
  };

  return (
    <>
      <Ul open={open}>
        {current?.emailVerified && (
          <li
            onClick={() =>
              history.push(`/profile/${user.user_id}`, { post: user })
            }
          >
            Profile
          </li>
        )}
        {current?.admin && (
          <li
            onClick={() => history.push('/admin')}
            className={path.includes('admin') ? 'Active' : 'NotActive'}
          >
            Admin Panel
          </li>
        )}
        <li
          onClick={() => history.push('/aboutus')}
          className={path.includes('aboutus') ? 'Active' : 'NotActive'}
        >
          About YEFI
        </li>
        <li
          onClick={() => history.push('/our_impact')}
          className={path.includes('our_impact') ? 'Active' : 'NotActive'}
        >
          Our Impact
        </li>
        <li
          onClick={() => history.push('/our_team')}
          className={path.includes('our_team') ? 'Active' : 'NotActive'}
        >
          Our Team
        </li>
        <li
          onClick={() => history.push('/contactus')}
          className={path.includes('contactus') ? 'Active' : 'NotActive'}
        >
          Contact Us
        </li>

        <li
          onClick={() => {
            if (!current.isEmpty) {
              setModalShow(true);
            } else {
              history.push(`/signin/return?from=${path.replace('/', '%2F')}`);
            }
          }}
        >
          {!current.isEmpty ? 'Sign out' : 'Sign in'}
        </li>
        <li id="out" onClick={() => history.push(`/donate`)}>
          Donate
        </li>
      </Ul>
      <InputModal
        payload={payload}
        show={modalShow}
        onActionFun={() => {
          setModalShow(false);
          if (payload.action === 'Done') return;
          console.log('emailed we out');

          signout(signOutCallBack);
        }}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};
const mapStateToProps = ({ yng_datastore, yng_authReducer }) => {
  return {
    current: yng_authReducer.current,
    user: yng_datastore.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signout,
      updateFireAction,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(RightNav);
