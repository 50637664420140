import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useHistory } from 'react-router-dom';
import logo from '../assets/logo.jpeg';
import { Formik } from 'formik';
import {object,string,ref} from 'yup';
import { ConnectedFocusError } from 'focus-formik-error';
import { MSG_SIGNUP_ERROR } from '../constants';

import {omit} from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { signup, signout, saveUserToFirestore } from '../actions';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { InputGroup } from 'react-bootstrap';
import { FaEyeSlash, FaEye } from 'react-icons/fa';
import InputModal from '../components/InputModal';
import { user } from '../constants/AppData';

const Signup = ({ authMsg, storeMsg, signup, saveUserToFirestore }) => {
  const [data, setData] = useState({
    fullname: '',
    email: '',
    password: '',
    confirm: '',
    show: false,
    saving: false,
    disable: false,
    checked: false,
    clicked: '',
  });
  const [payload, setPayload] = useState({
    data: {
      email: '',
      title: 'Reset Password',
      body: 'A reset password link will be sent to your email. Please enter your email address below',
    },
    req: ['email'],
    action: 'Send Link',
  });
  const [isDisconnected, setDisconnected] = useState(false);
  const [isLink, setLink] = useState(false);
  const [isErr, setErr] = useState(false);

  const [show, setShow] = useState(false);
  const history = useHistory();
  const location = new URL(window.location.href);

  const from = location.searchParams.get('from').replace('%2F', '/');

  const handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', {
          mode: 'no-cors',
        })
          .then(() => {
            setDisconnected(false);
            return clearInterval(webPing);
          })
          .catch(() => setDisconnected(true));
      }, 2000);
      return;
    }
    return setDisconnected(true);
  };
  useEffect(() => {
    handleConnectionChange();
    window.addEventListener('online', handleConnectionChange);
    window.addEventListener('offline', handleConnectionChange);
  }, []);
  useEffect(() => {
    if (isDisconnected) {
      setPayload({
        data: {
          title: 'Network Error',
          body: 'Please check your internet connection and try again',
        },
        req: ['title'],
        uploading: false,
        action: 'Okay',
      });
    }
    setLink(isDisconnected);
    if (authMsg === MSG_SIGNUP_ERROR) {
      setData({
        ...data,
        show: true,
        saving: false,
        disable: false,
      });
    }
  }, [authMsg, storeMsg, isDisconnected]);

  const signupCallback = (currentUser, err) => {
    console.log('we baka ', currentUser);

    if (currentUser) {
      saveUserToFirestore(currentUser, signupUserCallback, currentUser);
    } else {
      setPayload({
        data: { title: 'Error', body: err },
        req: ['title'],
        uploading: false,
        action: 'Okay',
      });
      setErr(true);
    }
  };

  const signupUserCallback = (userDb, isSignup, user_data, caller, err) => {
    console.log('signupUserCallback get data id ', userDb);

    if (err) {
      setPayload({
        data: { title: 'Error', body: err },
        req: ['title'],
        uploading: false,
        action: 'Okay',
      });
      setErr(true);
    } else {
      history.replace('/email_verification', {
        type: 'Email Verification',
        email: userDb.email,
        from,
      });
    }
  };
  const Register = (data) => {
    setData({
      ...data,

      disable: true,
    });
    signup(
      { ...user, ...omit(data, ['password', 'confirm']) },
      data.password,
      from,
      signupCallback
    );
  };

  return (
    <div className="Container bgg">
      <div className="Login-form">
        <div className="App">
          <img alt="" src={logo} width={140} className="App" />
          <h1>Register</h1>
        </div>
        <Formik
          initialValues={{
            username: '',
            email: '',
            password: '',
            confirm: '',
          }}
          validationSchema={object({
            username: string().required('email required'),
            password: string()
              .min(7, 'Must be atleast 7 characters')
              .required('password required'),
            email: string()
              .email('Enter valid email')
              .required('email required'),
            confirm: string()
              .oneOf([ref('password'), null], 'Passwords must match')
              .required('password required'),
            /* terms: Yup.bool()
              .required()
              .oneOf([true], 'Terms must be accepted'), */
          })}
          onSubmit={Register}
        >
          {(formik) => (
            <Form noValidate onSubmit={formik.handleSubmit}>
              <ConnectedFocusError />

              <Form.Group
                as={Row}
                controlId="formBasicName"
                className="is-invalid"
              >
                <Col>
                  <Form.Label>{'Full name'}</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <Form.Control
                    type="text"
                    placeholder={'Full name'}
                    name="username"
                    {...formik.getFieldProps('username')}
                    isValid={formik.touched.username && !formik.errors.username}
                    isInvalid={
                      formik.touched.username && !!formik.errors.username
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.username}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formBasicEmail">
                <Col>
                  <Form.Label>Email</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    name="email"
                    {...formik.getFieldProps('email')}
                    isValid={formik.touched.email && !formik.errors.email}
                    isInvalid={formik.touched.email && !!formik.errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formPlaintextPassword">
                <Col>
                  <Form.Label>Password</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={show ? 'text' : 'password'}
                      placeholder="Password"
                      name="password"
                      {...formik.getFieldProps('password')}
                      isValid={
                        formik.touched.password && !formik.errors.password
                      }
                      isInvalid={
                        formik.touched.password && !!formik.errors.password
                      }
                    />

                    <Button
                      onClick={() => setShow(!show)}
                      variant="outline-secondary"
                    >
                      {show ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              <Form.Group as={Row} controlId="formPlaintextConfirm">
                <Col>
                  <Form.Label>Confirm Password</Form.Label>
                </Col>
                <Col xs={8} sm={8} md={8} lg={8}>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type={show ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      id="confirm"
                      name="confirm"
                      {...formik.getFieldProps('confirm')}
                      isValid={formik.touched.confirm && !formik.errors.confirm}
                      isInvalid={
                        formik.touched.confirm && !!formik.errors.confirm
                      }
                    />
                    <Button
                      onClick={() => setShow(!show)}
                      variant="outline-secondary"
                    >
                      {show ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Col>
              </Form.Group>
              {/* <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Append>
                    <Form.Check
                      required
                      name="terms"
                      checked={formik.values.terms}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.terms && !!formik.errors.terms}
                      feedback={formik.errors.terms}
                      id="validationFormik0"
                    />
                  </InputGroup.Append>
                  <Form.Label
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => {
                      setPayload({
                        ...payload,
                        showInput: false,
                        title: 'Terms and Conditions',
                        body: `Loanpax is user friendly loan market platform that plays a vital role in lending and borrowing transactions. This system is developed for Borrowers and Lenders from any business status. It is for this reason that startup lenders can share the market platform with big players such as banks without any worry. Borrowers from all walks of life can find loans of their capacities with the matching lenders.

LENDER
A lender can register on this platform and our able support team will verify the account and approve it as long as they are registered with relevant authorities.

REQUIREMENTS
Lender can add the loans of their choices and set their loan requirements in order to be matched with qualified borrowers. Some financial lending firms can add staffs to their own accounts will be reviewing and approving the applications from the borrowers.

COSTS
After a lender has registered and added loans to their accounts, it is required that that they enjoy 30 days free service to experience the platform. After the trial period end, the lender should purchase applications which are in form packages depending the number of applications needed.`,
                        action: 'I Agree',
                      });
                      setErr(true);
                    }}
                  >
                    I agree to the terms and conditions
                  </Form.Label>
                </InputGroup>
              </Form.Group> */}

              <Button
                variant="primary"
                type="submit"
                size="lg"
                block
                disabled={data.disable}
              >
                <div
                  style={{
                    width: '100%',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: data.disable ? 'flex' : 'none',
                  }}
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Signing up.....
                </div>
                <div
                  style={{
                    width: '100%',
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: !data.disable ? 'flex' : 'none',
                  }}
                >
                  Sign up
                </div>
              </Button>
            </Form>
          )}
        </Formik>
        <br />
        <br />
        <hr />
        {/* <StyledFirebaseAuth
          uiConfig={{
            signInFlow: "popup",
            signInOptions: [
              firebase.auth.GoogleAuthProvider.PROVIDER_ID,
              {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                  type: "image", // 'audio'
                  size: "normal", // 'invisible' or 'compact'
                  badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
                },
                defaultCountry: "ZM",
                defaultNationalNumber: "1234567890",

                loginHint: "+2601234567890",
              },
            ],
            callbacks: {
              signInSuccessWithAuthResult: (authResult) => {
                console.log("success get data id ", authResult.user.uid);
                const user_data = {
                  ...user,
                  email: authResult.user.email,
                  user_id: authResult.user.uid,
                  username: authResult.user.displayName,
                  number: authResult.user.phoneNumber,
                  photoUrl: authResult.user.photoURL,
                  type: user_type,
                };
                updateFireAction(user_data);
                setData({
                  ...data,
                  clicked: "phone",
                });
                setOpt(true);
                console.log("auth done");
                getUserFirestore(
                  authResult.user.uid,
                  user_type,
                  false,
                  signupUserCallback,
                  true,
                  user_data
                );

                return false;
              },
            },
          }}
          firebaseAuth={firebase.auth()}
        /> */}
      </div>
      {/*  <LoadingModal show={opt} /> */}
      <InputModal
        payload={payload}
        show={isLink}
        onActionFun={() => {
          setLink(false);
        }}
        onHide={() => setLink(false)}
      />
      <InputModal
        payload={payload}
        show={isErr}
        onActionFun={() => {
          setErr(false);
        }}
        onHide={() => setErr(false)}
      />
    </div>
  );
};
function mapStateToProps(state) {
  return {
    authMsg: state.yng_authReducer.authMsg,
    storeMsg: state.yng_datastore.storeMsg,
  };
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      signup,
      signout,
      saveUserToFirestore,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Signup);
