import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import useFlutterwave from '../hooks/useFlutterwave';
import { verifyTxn, savePostToFirestore } from '../actions';
import { useHistory } from 'react-router';
import { Container, FloatingLabel, Form } from 'react-bootstrap';
import SubNav from '../components/SubNav';
import { countries } from '../constants/AppData';
import styled from 'styled-components';
import { Formik } from 'formik';
import {object,string,number} from 'yup';
import CustomBtn from '../components/CustomBtn';
import {find} from 'lodash';
import { ConnectedFocusError } from 'focus-formik-error';
import { GREEN, LIGHTBLUE } from '../constants/Colors';
const Div = styled.div`
  width: 100%;
  border: rgb(229, 230, 229) 1px solid;
  border-radius: 20px;
  margin-bottom: 20px;

  .top {
    border-radius: 20px 20px 0px 0px;
    padding: 27px;
    background-color: ${GREEN};
  }
  
  }
`;
const Donate = ({ verifyTxn, savePostToFirestore, user, currency, code }) => {
  const history = useHistory();
  const { user_id, email, phone, username } = user;
  const currencyCodes = countries.map((position) => position.currencyCode);

  const [uploading, setUploading] = useState(false);

  const funCallback = () => {
    console.log('data funCallback ');
    setUploading(false);

    history.replace('/');
  };
  const txnCallback = (txn) => {
    console.log('data txnCallback started ', txn);
    setUploading(true);

    verifyTxn(
      {
        ...txn,
        user_id,
        type: 'donate',
        days: 365,
        date: new Date().toString().substring(4, 15),
      },
      funCallback
    );
  };

  const UpdateFlutterwave = (doc) => {
    const { user_id, email, phone, username, currency, code } = doc;
    savePostToFirestore([
      { ...doc, collection: 'donations', post_id: user_id },
    ]);

    useFlutterwave(
      doc,
      currency,
      code,
      user_id,
      email,
      phone,
      username,
      'Donation',
      txnCallback
    );
  };
  const getCode = (currency) => {
    return find(countries, {
      currencyCode: currency,
    })['countryCode'];
  };

  return (
    <Container>
      <SubNav />
      <br />
      <Div
        style={{ border: 'rgb(229,230,229) 1px solid', borderRadius: '20px' }}
      >
        <div className="top">
          <h3>{'Make a Donation'}</h3>
        </div>
        <div style={{ padding: 17 }}>
          <Formik
            initialValues={{
              username,
              user_id,
              email,
              phone,
              currency,
              code,
            }}
            validationSchema={object({
              username: string().required('name required'),
              email: string()
                .email('Enter valid email')
                .required('email required'),
              phone: number().required('number required'),
              amount: number().required('amount required'),
            })}
            onSubmit={UpdateFlutterwave}
          >
            {(formik) => (
              <Form
                noValidate
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit(e);
                }}
              >
                <ConnectedFocusError />
                <Form.Group controlId="formBasicusername">
                  <FloatingLabel label="username">
                    <Form.Control
                      type="username"
                      name="username"
                      size="sm"
                      placeholder="Enter username"
                      {...formik.getFieldProps('username')}
                      isValid={
                        formik.touched.username && !formik.errors.username
                      }
                      isInvalid={
                        formik.touched.username && !!formik.errors.username
                      }
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.username}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <FloatingLabel label="email">
                    <Form.Control
                      type="email"
                      name="email"
                      size="sm"
                      placeholder="Enter email"
                      {...formik.getFieldProps('email')}
                      isValid={formik.touched.email && !formik.errors.email}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicphone">
                  <FloatingLabel label="phone">
                    <Form.Control
                      type="phone"
                      name="phone"
                      size="sm"
                      placeholder="Enter phone"
                      {...formik.getFieldProps('phone')}
                      isValid={formik.touched.phone && !formik.errors.phone}
                      isInvalid={formik.touched.phone && !!formik.errors.phone}
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="floatingSelect">
                  <FloatingLabel label={'Select currency'}>
                    <Form.Select
                      aria-label="Select currency"
                      name="currency"
                      value={formik.values.currency}
                      onChange={(event) => {
                        const code = getCode(event.currentTarget.value);
                        formik.setFieldValue('code', code);
                        formik.handleChange(event);
                      }}
                      isValid={
                        formik.touched.currency && !formik.errors.currency
                      }
                      isInvalid={
                        formik.touched.currency && !!formik.errors.currency
                      }
                    >
                      {currencyCodes.map((item, idx) => (
                        <option key={`${item}${idx}`} value={item}>
                          {item}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.currency}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicamount">
                  <FloatingLabel label="amount">
                    <Form.Control
                      type="amount"
                      name="amount"
                      size="sm"
                      placeholder="Enter amount"
                      {...formik.getFieldProps('amount')}
                      isValid={formik.touched.amount && !formik.errors.amount}
                      isInvalid={
                        formik.touched.amount && !!formik.errors.amount
                      }
                    />
                  </FloatingLabel>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.amount}
                  </Form.Control.Feedback>
                </Form.Group>
                <div
                  style={{
                    display: 'flex',
                    margin: '17px 0px',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                  }}
                >
                  <CustomBtn
                    label="Donate"
                    type="submit"
                    uploading={uploading}
                    disabled={uploading}
                    color1={!uploading ? GREEN : '#c84b1565'}
                    color2={!uploading ? LIGHTBLUE : '#c84b1565'}
                    margin="0px"
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Div>
    </Container>
  );
};

const mapStateToProps = ({ yng_datastore }) => {
  return {
    user: yng_datastore.user,
    currency: yng_datastore.loc.currency,
    code: yng_datastore.loc.code,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      verifyTxn,
      savePostToFirestore,
    },
    dispatch
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(Donate);
