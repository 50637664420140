import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { ORANGE } from "../constants/Colors";

const Div = styled.div`
  width: 100%;
  height: 100%;
  background: rgb(229, 230, 229);
  padding: 17px 47px;
  justify-content: center;
  align-items: center;

  h5 {
    text-align: center;
    color: ${ORANGE};
  }
  p {
    text-align: center;
  }
`;
const Squared = ({ status, title, type }) => {
  const history = useHistory();

  return (
    <Div
      style={{ cursor: "pointer" }}
      onClick={() =>
        history.push(`/users/params?path=users&type=${type}&sel=0`)
      }
    >
      <h5>{status}</h5>
      <p>{title}</p>
    </Div>
  );
};

export default Squared;
