import React from 'react';
import { useHistory } from 'react-router';
import { GREEN, LIGHTBLUE } from '../constants/Colors';
import CustomBtn from './CustomBtn';

const UserCells = ({ data }) => {
  const history = useHistory();

  return (
    <tr>
      <td>{data.username}</td>
      <td>{data.address}</td>

      <td>{data.phone}</td>
      <td>{data.email}</td>

      <td>{new Date(data.sub).toString().substring(4, 15)}</td>
      <td>{`K ${data.amount}`}</td>

      <td>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CustomBtn
            label={'See more'}
            type="button"
            btnClicked={() =>
              history.push(`/profile/${data.user_id}`, { post: data })
            }
            color1={GREEN}
            color2={LIGHTBLUE}
            margin="3px"
          />
        </div>
      </td>
    </tr>
  );
};

export default UserCells;
