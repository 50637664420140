import React from 'react';

import './App.css';
import { Provider } from 'react-redux';
import { googleMapsApiKey } from './config/google_maps';

import scriptLoader from 'react-async-script-loader';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { persistedStore, store } from './store';
import AuthIsLoaded from './FbAuth/AuthIsLoaded';
import { PersistGate } from 'redux-persist/integration/react';
import PageNotFound from './pages/404';
import Home from './pages/Home';
import Signin from './pages/signin';
import Signup from './pages/signup';
import AuthLink from './pages/AuthLink';
import Auth from './pages/Auth';
import Contactus from './pages/Contactus';
import Pay from './pages/Pay';
import PayRoute from './routing/PayRoute';
import UserAccRoute from './routing/UserAccRoute';
import EditProfile from './pages/EditProfile';
import Apanel from './pages/Apanel';
import Users from './pages/Users';
import Profile from './pages/Profile';
import AdminRoute from './routing/AdminRoute';
import Overview from './pages/Overview';
import Impact from './pages/Impact';
import OurTeam from './pages/OurTeam';
import Donate from './pages/Donate';
function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistedStore} loading={null}>
        <Router>
          <AuthIsLoaded>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/signin/:user" component={Signin} />
              <Route exact path="/signup/:user" component={Signup} />
              <Route exact path="/contactus" component={Contactus} />
              <Route exact path="/aboutus" component={Overview} />
              <Route exact path="/our_impact" component={Impact} />

              <Route exact path="/our_team" component={OurTeam} />
              <Route exact path="/donate" component={Donate} />
              <Route exact path="/email_verification">
                <AuthLink />
              </Route>
              <Route exact path="/auth/:id">
                <Auth />
              </Route>
              <AdminRoute exact path="/admin">
                <Apanel />
              </AdminRoute>
              <UserAccRoute exact path="/users/:id">
                <Users />
              </UserAccRoute>
              <UserAccRoute exact path="/profile/:id">
                <Profile />
              </UserAccRoute>
              <PayRoute exact path="/subscribe">
                <Pay />
              </PayRoute>
              <UserAccRoute exact path="/edit_profile/:id">
                <EditProfile />
              </UserAccRoute>

              <Route exact path="/404">
                <PageNotFound />
              </Route>
              <Redirect to="/404" />
            </Switch>
          </AuthIsLoaded>
        </Router>
      </PersistGate>
    </Provider>
  );
}
export default scriptLoader([
  `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&libraries=places`,

  'https://checkout.flutterwave.com/v3.js',
])(App);
