import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
const AdminRoute = ({ location, children, ...rest }) => {
  const auth = useSelector((state) => state.yng_authReducer.current);
  const { email, emailVerified, admin } = auth;
  const reAuth = location.state?.reAuth;

  console.log('get auth ', auth);
  console.log('get auth current ', admin);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        admin ? (
          <>
            {!email || emailVerified || reAuth ? (
              children
            ) : (
              <Redirect
                to={{
                  pathname: `/email_verification`,
                  state: {
                    from: location.pathname,
                    email,
                    type: 'Email Verification',
                  },
                }}
              />
            )}
          </>
        ) : (
          <Redirect
            to={{
              pathname: `/signin/return?from=${location.pathname.replace(
                '/',
                '%2F'
              )}`,
              state: {
                from: location.pathname,
                type: 'any',
                num: 1,
                subPath: '',
              },
            }}
          />
        )
      }
    />
  );
};

export default AdminRoute;
