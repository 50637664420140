import { ConnectedFocusError } from "focus-formik-error";
import { Formik } from "formik";
import React, { useState } from "react";
import {
  Modal,
  Row,
  Form,
  FloatingLabel,
  InputGroup,
  Col,
} from "react-bootstrap";
import {pick,omit} from "lodash";

import * as Yup from "yup";
import CustomBtn from "./CustomBtn";
import { GREEN, LIGHTBLUE, ORANGE } from "../constants/Colors";
function InputModal(props) {
  const { data, action, req, uploading } = props.payload;
  const { title, body, total, currency } = data;
  const [product_total, setProduct] = useState(total);
  let validate = {
    title: Yup.string().required("title required"),
    email: Yup.string().email("Enter valid email").required("email required"),
    info: Yup.string().required("info required"),
    USD: Yup.number().integer().required("dollar amount required"),
    ZMW: Yup.number().integer().required("kwacha amount required"),
    duration: Yup.number().integer().required("duration amount required"),
    password: Yup.string()
      .required("password required")
      .matches(/(?=.{8,})/, "Must Contain at least 8 Characters"),
    qty: Yup.number().integer().required("qty required"),
    fullname: Yup.string().required("fullname  required"),
    enterprise: Yup.string().required("enterprise  required"),
    address: Yup.string().required("location  required"),
    size: Yup.string().required("size of land  required"),
  };
  validate = pick(validate, req);
  const handleChange = (input) => {
    try {
      const product = Number(input) * data.price;
      setProduct(product);
    } catch (error) {}
  };
  const submitToFb = (data) => {
    props.setUploading(true);
    let payload = data;

    if (data.USD) {
      payload = {
        ...payload,
        USD: Number(payload.USD),
        ZMW: Number(payload.ZMW),
      };
    }
    if (data.num) {
      payload = {
        ...omit(payload, ["total", "percent", "currency", "price"]),
        num: Number(payload.num),
        product_total: Number(product_total),
        pay_percent: Number(payload.pay_percent),
        [currency]:
          Math.round(Number(product_total) * Number(payload.pay_percent)) / 100,
        payment:
          Math.round(Number(product_total) * Number(payload.pay_percent)) / 100,
      };
    }
    props.onActionFun(omit(payload, ["body", "title"]));
  };
  return (
    <Modal
      show={props.show}
      onHide={props.onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={data}
          validationSchema={Yup.object(validate)}
          onSubmit={submitToFb}
        >
          {(formik) => (
            <Form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                formik.handleSubmit(e);
              }}
            >
              <ConnectedFocusError />
              <Row>
                {title !== "Terms and Conditions" ? (
                  <h6>{body}</h6>
                ) : (
                  <p style={{ whiteSpace: "pre-line" }}>{body}</p>
                )}
              </Row>
              {data.email !== undefined && (
                <Form.Group controlId="formEmail">
                  <FloatingLabel label="email">
                    <Form.Control
                      type="email"
                      name="email"
                      size="sm"
                      placeholder="Enter email"
                      {...formik.getFieldProps("email")}
                      isValid={formik.touched.email && !formik.errors.email}
                      isInvalid={formik.touched.email && !!formik.errors.email}
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.fullname !== undefined && (
                <Form.Group controlId="formfullname">
                  <FloatingLabel label="fullname">
                    <Form.Control
                      type="text"
                      name="fullname"
                      size="sm"
                      placeholder="Enter fullname"
                      {...formik.getFieldProps("fullname")}
                      isValid={
                        formik.touched.fullname && !formik.errors.fullname
                      }
                      isInvalid={
                        formik.touched.fullname && !!formik.errors.fullname
                      }
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.fullname}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.password !== undefined && (
                <Form.Group controlId="formpassword">
                  <FloatingLabel label="password">
                    <Form.Control
                      type="text"
                      name="password"
                      size="sm"
                      placeholder="Enter password"
                      {...formik.getFieldProps("password")}
                      isValid={
                        formik.touched.password && !formik.errors.password
                      }
                      isInvalid={
                        formik.touched.password && !!formik.errors.password
                      }
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.enterprise !== undefined && (
                <Form.Group controlId="formenterprise">
                  <FloatingLabel label="enterprise">
                    <Form.Control
                      type="text"
                      name="enterprise"
                      size="sm"
                      placeholder="Enter enterprise"
                      {...formik.getFieldProps("enterprise")}
                      isValid={
                        formik.touched.enterprise && !formik.errors.enterprise
                      }
                      isInvalid={
                        formik.touched.enterprise && !!formik.errors.enterprise
                      }
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.enterprise}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.address !== undefined && (
                <Form.Group controlId="formaddress">
                  <FloatingLabel label="location">
                    <Form.Control
                      type="text"
                      name="address"
                      size="sm"
                      placeholder="Enter location"
                      {...formik.getFieldProps("address")}
                      isValid={formik.touched.address && !formik.errors.address}
                      isInvalid={
                        formik.touched.address && !!formik.errors.address
                      }
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.address}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.size !== undefined && (
                <Form.Group controlId="formsize">
                  <FloatingLabel label="size of land">
                    <Form.Control
                      type="text"
                      name="size"
                      size="sm"
                      placeholder="Enter size of land"
                      {...formik.getFieldProps("size")}
                      isValid={formik.touched.size && !formik.errors.size}
                      isInvalid={formik.touched.size && !!formik.errors.size}
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.size}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.num !== undefined && (
                <Form.Group controlId="formnum">
                  <FloatingLabel label="num">
                    <Form.Control
                      type="text"
                      name="num"
                      num="sm"
                      placeholder="Enter number of items"
                      readOnly={data.pos}
                      value={formik.values.num}
                      onChange={(e) => {
                        formik.handleChange(e);

                        handleChange(e.currentTarget.value);
                      }}
                      isValid={formik.touched.num && !formik.errors.num}
                      isInvalid={formik.touched.num && !!formik.errors.num}
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.num}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.total !== undefined && (
                <h6
                  style={{
                    color: ORANGE,
                  }}
                >
                  {" "}
                  <span
                    style={{
                      color: "black",
                    }}
                  >
                    Total:
                  </span>
                  {` ${data.currency} ${product_total}`}
                </h6>
              )}
              {data.pay_percent !== undefined && (
                <Form.Group controlId="floatingSelect">
                  <FloatingLabel label="Select payment percentage">
                    <Form.Select
                      aria-label="Floating label select example"
                      name="pay_percent"
                      {...formik.getFieldProps("pay_percent")}
                    >
                      {data.percent.map((item) => (
                        <option key={item} value={item}>
                          {`Pay ${item}%`}
                        </option>
                      ))}
                    </Form.Select>
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.opt}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.USD !== undefined && data.ZMW !== undefined && (
                <Form.Group as={Row} controlId="formMinimium">
                  <Col>
                    <InputGroup>
                      <InputGroup.Text>$</InputGroup.Text>

                      <Form.Control
                        placeholder="0"
                        name="USD"
                        value={formik.values.USD?.toLocaleString(
                          navigator.language,
                          { minimumFractionDigits: 0 }
                        )}
                        onChange={formik.handleChange}
                        isValid={formik.touched.USD && !formik.errors.USD}
                        isInvalid={formik.touched.USD && !!formik.errors.USD}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.USD}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                  <Col>
                    <InputGroup>
                      <InputGroup.Text>K</InputGroup.Text>

                      <Form.Control
                        placeholder="0"
                        name="ZMW"
                        value={formik.values.ZMW?.toLocaleString(
                          navigator.language,
                          { minimumFractionDigits: 0 }
                        )}
                        onChange={formik.handleChange}
                        isValid={formik.touched.ZMW && !formik.errors.ZMW}
                        isInvalid={formik.touched.ZMW && !!formik.errors.ZMW}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.ZMW}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Col>
                </Form.Group>
              )}
              {data.duration !== undefined && (
                <Form.Group controlId="formduration">
                  <FloatingLabel label="duration">
                    <Form.Control
                      type="text"
                      name="duration"
                      size="sm"
                      placeholder="Enter duration"
                      {...formik.getFieldProps("duration")}
                      isValid={
                        formik.touched.duration && !formik.errors.duration
                      }
                      isInvalid={
                        formik.touched.duration && !!formik.errors.duration
                      }
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.duration}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              {data.super_admin !== undefined && data.admin !== undefined && (
                <>
                  <Form.Check type="checkbox" id={`check-api`}>
                    <Form.Check
                      required
                      name="super_admin"
                      checked={formik.values.super_admin}
                      onChange={formik.handleChange}
                      isInvalid={
                        formik.touched.super_admin &&
                        !!formik.errors.super_admin
                      }
                    />
                    <Form.Check.Label>Super admin</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.super_admin}
                    </Form.Control.Feedback>
                  </Form.Check>
                  <Form.Check type="checkbox" id={`check-api`}>
                    <Form.Check
                      required
                      name="admin"
                      checked={formik.values.admin}
                      onChange={formik.handleChange}
                      isInvalid={formik.touched.admin && !!formik.errors.admin}
                    />
                    <Form.Check.Label>Admin</Form.Check.Label>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.admin}
                    </Form.Control.Feedback>
                  </Form.Check>
                </>
              )}
              {data.info !== undefined && (
                <Form.Group controlId="formDescription">
                  <FloatingLabel label="description">
                    <Form.Control
                      type="text"
                      name="info"
                      as="textarea"
                      rows={4}
                      style={{ height: "100px" }}
                      placeholder="description"
                      {...formik.getFieldProps("info")}
                      isValid={formik.touched.info && !formik.errors.info}
                      isInvalid={formik.touched.info && !!formik.errors.info}
                    />
                  </FloatingLabel>

                  <Form.Control.Feedback type="invalid">
                    {formik.errors.info}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "7px 0px",
                  justifyContent: "flex-end",
                }}
              >
                <CustomBtn
                  label={action}
                  type="submit"
                  uploading={uploading}
                  disabled={uploading}
                  color1={uploading ? "#c84b1565" : GREEN}
                  color2={uploading ? "#c84b1565" : LIGHTBLUE}
                  margin="0px 0px 0px 13px"
                />
              </div>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default InputModal;
